import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import LoadingBar from "react-top-loading-bar";

import ProjectsService from "../../services/ProjectService";
import "./Portfolio.css";

function Portfolio(props) {
  const { category_id } = useParams();
  const [value, setValue] = useState(category_id ?? "all");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ref = useRef(null);

  const Project = new ProjectsService();
  const [category, setCategory] = useState([
    {
      title: "",
      category: "",
      projects: [],
    },
  ]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const getCategories = (lang) => {
    ref.current.continuousStart();
    Project.getCategories(lang).then((res) => {
      setCategory(
        res.data.categories
          .map(function (item) {
            item.projects.map((i) => {
              i.category = item.category;
            });
            switch (item.category) {
              case "web":
                return {
                  category: item.category,
                  title: t("web"),
                  projects: item.projects,
                };
              case "mobile":
                return {
                  category: item.category,
                  title: t("mobile"),
                  projects: item.projects,
                };
              default:
                return {
                  category: item.category,
                  title: t("avto"),
                  projects: item.projects,
                };
            }
          })
          .splice(1, 4)
      );
      ref.current.complete();
    });
  };

  useEffect(() => {
    getCategories(props.lang);
  }, [props.lang]);

  const [data, setData] = useState([]);

  useEffect(() => {
    Project.getProjects(props.lang)
      .then((res) => {
        setData(res.data.projects);
      })
      .catch((error) => {
        return navigate("/404");
      });
  }, [props.lang]);

  return (
    <div className="portfolio">
      <Helmet>
        <meta charSet="utf-8" />
        <title>USOFT - {t("portfolio")}</title>
        <meta
          name="description"
          content="Портфолио разработанных сайтов и  мобильных приложений."
        />
      </Helmet>

      <LoadingBar height={3} color="red" ref={ref} />
      <div className="container">
        <ol
          itemscope
          itemtype="http://schema.org/BreadcrumbList"
          className="breadcrumb"
        >
          <li
            itemprop="itemListElement"
            itemscope
            itemtype="http://schema.org/ListItem"
          >
            <Link
              itemscope
              itemtype="http://schema.org/Thing"
              itemprop="item"
              to="/"
            >
              <span itemprop="name"> {t("main")}</span>
            </Link>
            <meta itemprop="position" content="1" />
          </li>
          <li
            itemprop="itemListElement"
            itemscope
            itemtype="http://schema.org/ListItem"
          >
            /<span itemprop="name"> {t("portfolio")} </span>
            <meta itemprop="position" content="2" />
          </li>
        </ol>
        <h1>{t("portfolio")}</h1>
        <Box>
          <TabContext value={value}>
            <Box>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                aria-label="scrollable prevent tabs example"
              >
                <Tab disableRipple label={t("all")} value="all" />
                {category.map((item, index) => (
                  <Tab
                    disableRipple
                    key={index}
                    label={item.title}
                    value={item.category}
                  />
                ))}
              </TabList>
            </Box>
            <TabPanel value="all">
              <div className="row">
                {data.map((v) => (
                  <div
                    key={v.id}
                    className="col-md-6 d-flex d-lg-block flex-wrap justify-content-center col-12"
                  >
                    <div className="work-card">
                      <Link to={`/portfolio/${v.category}/${v.slug}`}>
                        <h2>{v.title}</h2>
                      </Link>
                      <div className="work-card-layout">
                        <img
                          width="572"
                          height="350"
                          src={v.image}
                          alt={v.title}
                        />
                        <Link
                          className="portfolio-layout"
                          to={`/portfolio/${v.category}/${v.slug}`}
                        >
                          {t("about")}
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </TabPanel>
            {category.map((item, index) => (
              <TabPanel key={index} value={item.category}>
                <div className="row">
                  {item.projects.map((v) => (
                    <div
                      key={v.id}
                      className="col-md-6 d-flex d-lg-block flex-wrap justify-content-center col-12"
                    >
                      <div className="work-card">
                        <Link to={`/portfolio/${v.category}/${v.slug}`}>
                          <h2>{v.title}</h2>
                        </Link>
                        <div className="work-card-layout">
                          <img
                            width="572"
                            height="350"
                            loading="lazy"
                            src={v.image}
                            alt={v.title}
                          />
                          <Link
                            className="portfolio-layout"
                            to={`/portfolio/${v.category}/${v.slug}`}
                          >
                            {t("about")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </div>
    </div>
  );
}

export default Portfolio;
