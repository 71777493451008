import React, { useEffect, useState } from "react";
import ServicesService from "../../services/ServicesService";
import OrderModal from "../Modal/Modal";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import { Link } from "react-router-dom";
import SuccessModal from "../Modal/SuccessModal";
import "./Prices.css";

function Prices(props) {
  const [services, setServices] = useState([]);
  const [snack, setSnack] = useState(false);
  const [post, setPost] = useState({
    id: 0,
    posts: [],
  });

  const service = new ServicesService();

  const handleClose = () => {
    setSnack(false);
  };

  const openSuccess = (id, posts) => {
    setSnack(true);
    setPost({ id: id, posts: posts });
  };

  useEffect(() => {
    service.getServices(props.lang).then((res) => {
      setServices(res.data.services);
    });
  }, [props.lang]);

  return (
    <div className="container pricesbox">
      <h2>{t("prices-title")}</h2>
      <div className="row justify-content-center">
        {services.map((v) => (
          <div
            data-aos="zoom-in"
            key={v.id}
            className="col-lg-4 col-md-6 col-sm-7 mb-4"
          >
            <div className="pricebox">
              <div className="title">
                <Link to={`/services/${v.slug}`}>
                  <h3>{v.title} </h3>
                </Link>
                <img width="190" height="190" src={v.image} alt={v.title} />
                <p>{v.short_description}</p>
                {/* <h4> {t('prices-sum', { price: v.price })} </h4> */}
                <OrderModal
                  lang={props.lang}
                  openSuccess={openSuccess}
                  service_id={v.id}
                  clazz={"btn"}
                  style={{
                    backgroundColor: `${v.color}`,
                    boxShadow: `0 0 40px -10px ${v.color}`,
                  }}
                  name={t("prices-order")}
                  modaltitle={t("application-title")}
                />
              </div>
            </div>
          </div>
        ))}
        <SuccessModal
          lang={props.lang}
          id={post.id}
          posts={post.posts}
          success={snack}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
}

export default withTranslation()(Prices);
