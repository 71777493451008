import axios from 'axios'

class BlogService {

    getCategories = (lang = "uz") => {
        return axios.get('https://api.usoft.uz/api/posts/categories/', {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }

    getPost = (id, lang = "uz") => {
        return axios.get('https://api.usoft.uz/api/posts/' + id, {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }

    getAllPosts = (lang = "uz") => {
        return axios.get('https://api.usoft.uz/api/posts', {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }
}

export default BlogService