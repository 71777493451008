import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, Form } from 'react-bootstrap';
import VacancyService from '../../services/VacancyService';
import { TextField } from '@material-ui/core';
import InputMask from "react-input-mask";
import attach from "../../Images/attach.svg"
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
import './Modal.css'
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#291258',
    color: '#fff',
    borderRadius: '25px',
    boxShadow: 24,
    maxWidth: 400,
    overflow: "hidden",
    width: '100%',
    height: 480,
};

const override = css`
display: flex;
align-items: center;
 justify-content:center;
 border-color: red;
 width: 120px;
min-width: 100%;
margin: 20px 0;
`;

export default function VacancyModal(props) {
    const [open, setOpen] = React.useState(false)
    const [pend, setPend] = React.useState(false)
    const [file, setFile] = React.useState(null)
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const { t } = useTranslation()
    const vacancy = new VacancyService()

    const submit = event => {
        event.preventDefault()
        setPend(true)
        let phone = document.querySelector('#modal-phone').value
        let vacancy_id = props.vacancy_id
        let lang = props.lang
        vacancy.sendSummary(phone, vacancy_id, file, lang).then(res => {
            setOpen(false);
            setPend(false)
            props.openSuccess(res.data.id, res.data.posts)
        })
    }

    const handleFile = (event) => {
        const file = event.target.files[0]
        document.querySelector('#file-label').innerHTML = file.name
        setFile(file)
    }

    function remove(event) {
        event.preventDefault()
        document.getElementById('file-label').innerHTML = t('pin')
        setFile(null)
    }

    return (
        <div className={props.clazz}>
            <div style={props.style} className={props.className} onClick={handleOpen}>{props.name}</div>
            <Modal
                open={open}
                onClose={handleClose}>
                <Box sx={{ ...style }} style={props.readStyle}>
                    <div className="vacancy-inner">
                        <span className='closebtn' onClick={handleClose}>X</span>
                        <p className='vacancy-position'>{props.positionTitle}</p>
                        <div className='all-description' dangerouslySetInnerHTML={{ __html: props.content ? props.content : '' }}></div>
                        <h2 className="modal-title">{props.modaltitle}</h2>
                        <Form className="send-box d-block" onSubmit={submit}>
                            <label for='file' className='fileupload'>
                                <img className='me-2' src={attach} width="30" height="30" alt="set" />
                                <span id='file-label'> {t('pin')}</span>
                                <input
                                    required
                                    type="file"
                                    id='file'
                                    onChange={handleFile}
                                    hidden
                                />
                            </label>
                            {file ? <button className='times' type="button" onClick={remove}>X</button> : null}
                            <InputMask
                                mask='+\9\9\8 (99) 999-99-99'
                                disabled={false}
                                maskChar={null}
                            >
                                {() => <TextField className='text-input'
                                    label={t('telephone')}
                                    id="modal-phone"
                                    required />
                                }
                            </InputMask>
                            <div>
                                {pend ? <BeatLoader
                                    css={override}
                                    color={'#ff7878'}
                                    loading={pend}
                                    size={20}
                                /> :
                                    <Button className="modalbtn" variant="primary" type="submit">
                                        {t('send-request')}
                                    </Button>}
                            </div>
                            <div>
                                <p className='modal-vacancy-under'>{t('vacancy-mail')} <a href="mailto:job@usoft.uz">job@usoft.uz</a></p>
                                <p className='modal-bottom'>{t('vacancy-about')} <a href="/policy" target="_blank">{t('policy')}</a></p>
                            </div>
                        </Form>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
