import axios from 'axios'

class PartnersService {
    getPartners = (lang = "uz") => {
        return axios.get('https://api.usoft.uz/api/partners/', {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }
}

export default PartnersService