import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import BlogService from "../../services/BlogService";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import LoadingBar from "react-top-loading-bar";
import "./Blog.css";

function Blog(props) {
  const { category_slug } = useParams();
  const [value, setValue] = useState(category_slug ?? "all");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ref = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const Blog = new BlogService();
  const [category, setCategory] = useState([
    {
      id: 0,
      title: "",
      posts: [],
    },
  ]);
  const { slug } = useParams();
  useEffect(() => {
    ref.current.continuousStart();
    Blog.getCategories(props.lang)
      .then((res) => {
        setCategory(res.data.categories);
        setValue(category_slug ?? "all");
        ref.current.complete();
      })
      .catch((error) => {
        return navigate("/404");
      });
  }, [category_slug, slug, props.lang]);

  const [posts, setPosts] = useState([]);

  useEffect(
    (event) => {
      Blog.getAllPosts(props.lang).then((res) => {
        setPosts(res.data.posts);
      });
    },
    [props.lang]
  );

  return (
    <div className="blog">
      <Helmet>
        <meta charSet="utf-8" />
        <title>USOFT - {t("footer-blog")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content="Наш Блог - полезные статьи, обзоры событий и экспертное мнение"
        />
      </Helmet>
      <LoadingBar height={3} color="red" ref={ref} />
      <div className="container">
        <ol
          itemscope
          itemtype="http://schema.org/BreadcrumbList"
          className="breadcrumb"
        >
          <li
            itemprop="itemListElement"
            itemscope
            itemtype="http://schema.org/ListItem"
          >
            <Link
              itemscope
              itemtype="http://schema.org/Thing"
              itemprop="item"
              to="/"
            >
              <span itemprop="name"> {t("main")} </span>
            </Link>
            <meta itemprop="position" content="1" />
          </li>
          <li
            itemprop="itemListElement"
            itemscope
            itemtype="http://schema.org/ListItem"
          >
            {" "}
            /<span itemprop="name">{t("footer-blog")}</span>
            <meta itemprop="position" content="2" />
          </li>
        </ol>
        <h1>{t("footer-blog")}</h1>
        <Box>
          <TabContext value={value}>
            <Box>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                aria-label="scrollable prevent tabs example"
              >
                <Tab label={t("all")} value="all" />
                {category.map((v) => {
                  return <Tab key={v.id} label={v.title} value={v.slug} />;
                })}
              </TabList>
            </Box>
            <TabPanel value="all">
              <div className="row">
                {posts.map((v) => (
                  <div key={v.id} className="col-md-6 col-12">
                    <div className="blog-card">
                      <div className="work-card-layout">
                        <img
                          width="556"
                          height="250"
                          src={v.image}
                          alt={v.title}
                        />
                        <Link
                          className="portfolio-layout"
                          to={`/blog/${v.category_slug}/${v.slug}`}
                        >
                          {t("read-article")}
                        </Link>
                      </div>
                      <p className="blog-mini">
                        {v.category} | {v.created_at}
                      </p>
                      <Link to={`/blog/${v.category_slug}/${v.slug}`}>
                        <h3>{v.title}</h3>
                      </Link>
                      <h4 className="blog-parag">{v.short_description}</h4>
                      <Link
                        className="allworks"
                        to={`/blog/${v.category_slug}/${v.slug}`}
                      >
                        {t("read-article")}{" "}
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </TabPanel>
            {category.map((item) => (
              <TabPanel key={item.id} value={item.slug}>
                <div className="row">
                  {item.posts.map((v) => (
                    <div key={v.id} className="col-md-6 col-12">
                      <div className="blog-card">
                        <div className="work-card-layout">
                          <img
                            width="556"
                            height="250"
                            src={v.image}
                            alt={v.title}
                          />
                          <Link
                            className="portfolio-layout"
                            to={`/blog/${item.slug}/${v.slug}`}
                          >
                            {t("read-article")}
                          </Link>
                        </div>
                        <p className="blog-mini">
                          {v.category} | {v.created_at}
                        </p>
                        <Link to={`/blog/${item.slug}/${v.slug}`}>
                          <h3>{v.title}</h3>
                        </Link>
                        <h4 className="blog-parag">{v.short_description}</h4>
                        <Link
                          className="allworks"
                          to={`/blog/${item.slug}/${v.slug}`}
                        >
                          {t("read-article")}{" "}
                          <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </div>
    </div>
  );
}

export default Blog;
