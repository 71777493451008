import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      //dynamic
      about: "More",
      telephone: "Phone number",
      "send-request": "Send",
      "send-vacancies": "Send a resume",
      "read-full-description": "Read full description",
      "go-to": "Go to",
      all: "All",
      "as-well-as": "As well as",
      "read-article": "Read article",
      "read-also": "Read also",
      article: "Article",
      "main-tab-name":
        "Development of websites and mobile applications in Tashkent",
      "website-development": "Website development",
      sum: "sum",
      development: "Development",
      management: "Management",

      //success modal
      alert: "Successfully sent!",
      request: "Request number",
      waiting:
        "Our specialists will contact you as soon as possible, but while you are waiting for a call, we recommend reading:",
      "close-modal": "Close a window",

      //aplication
      "application-title": "Ready to discuss project details?",
      "application-bottom":
        "Leave a request and get a free consultation from our specialists",
      "application-call": "or call this phone",
      "policy-about":
        "By filling out and submitting this form you give your consent to",
      policy: "processing of personal data.",

      //vacancies application
      "vacancies-app-title":
        "Not familiar with the field, but want to work in IT?",
      "vacancies-app-bottom":
        "Do not wait! Leave a request and we will contact you.",
      "vacancy-modal-title": "Apply for job",
      pin: "Attach your resume",
      "vacancy-mail": "or send by email",
      "vacancy-about":
        "Fields marked with an asterisk * are required. By filling out and submitting this form, you give your consent to the",

      // header
      main: "Main",
      services: "Services",
      portfolio: "Portfolio",
      contacts: "Contacts",
      vacancies: "Vacancies",
      consultation: "Consultation",

      // slider
      "slider-btn": "Submit",

      //about
      awards: "Awards and certificates",
      letter: "Thanksgiving letters",

      //prices
      "prices-title": "The cost of our services",
      "prices-order": "Order",
      "prices-sum": "from {{price}} sum",

      // ourworks
      "ourworks-title": "Our works",
      web: "Web-sites",
      mobile: "Mobile applications",
      avto: "Business automation",
      "ourworks-all": "All works",
      popular: "Popular",

      //stages
      "stages-title": "Stages of development",
      "Application-stages": "Application development stages",

      //comments
      "comments-title": "Reviews of our clients",

      //clients
      "clients-title": "They trust us",

      //contacts
      "contact-facebook": "For ordering services: write to",
      "contact-mail": "Email address",
      "contact-phone1": "Fill out the Online form",
      "contact-phone": "or call our office on this phone:",
      "contact-time": "We work:",
      "contact-phonn": "number",
      "contact-adress": "Our address",

      //footer
      "footer-contact": "Contacts",
      "footer-adress": "Adress",
      "footer-mail": "Mail",
      "footer-sitemap": "Sitemap",
      "footer-services": "Services",
      "footer-portfolio": "Portfolio",
      "footer-blog": "Blog",
      "footer-vacancies": "Vacancies",
      "footer-about": "About",

      //error
      "error-title": "Page not found",
      "error-description":
        "We are sorry but the page you are looking for does not exist.",
      "error-btn": "Home page",

      // recaptcha
      "recaptcha-title": "Security Check: Please Verify You're Not a Robot",
    },
  },
  ru: {
    translation: {
      //dynamic
      about: "Подробнее",
      telephone: "Номер телефона",
      "send-request": "Отправить",
      "send-vacancies": "Отправить резюме",
      "read-full-description": "Читать полное описание",
      "go-to": "Перейти",
      all: "Все",
      "as-well-as": "А так же",
      "read-article": "Читать статью",
      "read-also": "Читайте так же",
      article: "Статья",
      "main-tab-name": "Разработка сайтов и Мобильных приложений в Ташкенте",
      "website-development": "Разработка веб-сайтов",
      sum: "сум",
      development: "Разработка",
      management: "Менеджмент",

      //success modal
      alert: "Успешно отправлено!",
      request: "Номер заявки",
      waiting:
        "Наши специалисты свяжутся с вами в ближайшее время, а пока ожидаете звонка звонка рекомендуем прочитать:",
      "close-modal": "Закрыть окно",

      //aplication
      "application-title": "Готовы обсудить детали проекта?",
      "application-bottom":
        "Оставьте заявку и получите бесплатную консультацию от наших специалистов",
      "application-call": "или свяжитесь по телефону",
      "policy-about":
        "Заполняя и отправляя данную форму вы даете свое согласие на",
      policy: "обработку персональных данных",

      //vacancies application
      "vacancies-app-title":
        "Не знакомы с этой областью, но хотите работать в ИТ?",
      "vacancies-app-bottom": "Не ждите! Оставьте заявку и мы с вами свяжемся.",
      "vacancy-modal-title": "Откликнуться на вакансию",
      pin: "Прикрепите свое резюме",
      "vacancy-mail": "или направьте на электронную почту",
      "vacancy-about":
        "Поля помеченные звездочкой * - обязательны к заполнению. Заполняя и отправляя данную форму вы даете свое согласие на",

      // header
      main: "Главная",
      services: "Услуги",
      portfolio: "Портфолио",
      contacts: "Контакты",
      vacancies: "Вакансии",
      consultation: "Консультация",

      // slider
      "slider-btn": "Оставить заявку",

      //about
      awards: "Награды и сертификаты",
      letter: "Благодарственные письма",

      //prices
      "prices-title": "Стоимость наших услуг",
      "prices-order": "Заказать",
      "prices-sum": "от {{price}} сум",

      // ourworks
      "ourworks-title": "Наши работы",
      web: "Веб-сайты",
      mobile: "Мобильные приложения",
      avto: "Автоматизация бизнеса",
      "ourworks-all": "Все работы",
      popular: "Популярные",

      //stages
      "stages-title": "Этапы разработки",
      "Application-stages": "Этапы разработки приложений",

      //comments
      "comments-title": "Отзывы наших клиентов",

      //clients
      "clients-title": "Нам доверяют",

      //contacts
      "contact-facebook": "По вопросам заказа услуг: пишите в",
      "contact-mail": "Электронный адрес",
      "contact-phone1": "Заполняйте Онлайн форму",
      "contact-phone": "или звоните к нам в офис по телефону:",
      "contact-time": "Работаем:",
      "contact-phonn": "телефону",
      "contact-adress": "Наш адрес",

      //footer
      "footer-contact": "Контакты",
      "footer-adress": "Адрес",
      "footer-mail": "Почта",
      "footer-sitemap": "Карта сайта",
      "footer-services": "Услуги",
      "footer-portfolio": "Портфолио",
      "footer-blog": "Блог",
      "footer-vacancies": "Вакансии",
      "footer-about": "О компании",

      //error
      "error-title": "Cтраница не найдена",
      "error-description":
        "Сожалеем, но страница, которую вы ищете, не существует.",
      "error-btn": "Главная страница",

      // recaptcha
      "recaptcha-title": "Проверка безопасности: подтвердите, что вы не робот",
    },
  },
  uz: {
    translation: {
      //dynamic
      about: "Batafsil",
      telephone: "Telefon raqamingiz",
      "send-request": "Yuborish",
      "send-vacancies": "Rezyume yuborish",
      "read-full-description": "To'liq tavsifni o'qing",
      "go-to": "O'tish",
      all: "Hammasi",
      "as-well-as": "Shuningdek",
      "read-also": "Shuningdek o'qing",
      "read-article": "Maqolani o'qing",
      article: "Maqola",
      "main-tab-name": "Web-saytlar va mobil ilovalarni ishlab chiqish",
      "website-development": "Web-sayt dasturlash",
      sum: "so'm",
      development: "Rivojlanish",
      management: "Boshqaruv",

      //success modal
      alert: "Muvaffaqiyatli yuborildi!",
      request: "So'rov raqami",
      waiting:
        "Mutaxassislarimiz siz bilan imkon qadar tezroq bog'lanadi, ammo siz qo'ng'iroqni kutayotganingizda, biz o'qishni tavsiya qilamiz:",
      "close-modal": "Ortga qaytish",

      //aplication
      "application-title": "Loyiha haqida muhokama qilishga tayyormisiz?",
      "application-bottom":
        "So'rov qoldiring va mutaxassislarimizdan bepul maslahat oling",
      "application-call": "yoki qo'ng'iroq qiling",
      "policy-about": "Ushbu shaklni to'ldirish va topshirish orqali siz",
      policy:
        "shaxsiy ma'lumotlarni qayta ishlashga roziligingizni bildirasiz.",

      //vacancies application
      "vacancies-app-title":
        "Bu soha bilan tanish emassiz, lekin IT sohasida ishlashni xohlaysizmi?",
      "vacancies-app-bottom":
        "Kutmang! So'rov qoldiring va biz siz bilan bog'lanamiz.",
      "vacancy-modal-title": "Ishga ariza berish",
      pin: "Rezyume joylang",
      "vacancy-mail": "yoki pochtangizni yuboring",
      "vacancy-about":
        "Yulduzcha * bilan belgilangan maydonlar shart. Ushbu shaklni to'ldirish va topshirish orqali siz",

      // header
      main: "Bosh saxifa",
      services: "Xizmatlar",
      portfolio: "Portfolio",
      contacts: "Kontaktlar",
      vacancies: "Vakansiya",
      consultation: "Maslahatlashuv",

      //slider
      "slider-btn": "Arizangizni yuboring",

      //about
      awards: "Mukofotlar va sertifikatlar",
      letter: "Minnatdorchilik xatlari",

      //prices
      "prices-title": "Xizmatlarimiz narxi",
      "prices-order": "Buyurtma",
      "prices-sum": "{{price}} so'mdan",

      // ourworks
      "ourworks-title": "Bizning ishlar",
      web: "Web sahifalar",
      mobile: "Mobil ilovalar",
      avto: "Biznesni avtomatlashtirish",
      "ourworks-all": "Hamma ishimiz",
      popular: "Ommabop",

      //stages
      "stages-title": "Rivojlanish bosqichlari",
      "Application-stages": "Ilovalarni ishlab chiqish bosqichlari",

      //comments
      "comments-title": "Mijozlarimizning sharhlari",

      //clients
      "clients-title": "Bizga ishonishadi",

      //contacts
      "contact-facebook": "Xizmatlarga buyurtma berish uchun:",
      "contact-mail": "Elektron manzil",
      "contact-phone1": "Onlayn formani to'ldiring",
      "contact-phone": "yoki ofisimizga qo'ng'iroq qiling:",
      "contact-time": "Ish vaqtimiz:",
      "contact-phonn": "telefon",
      "contact-adress": "Bizning manzil",

      //footer
      "footer-contact": "Kontaktlar",
      "footer-adress": "Manzil",
      "footer-mail": "Pochta",
      "footer-sitemap": "Sayt xaritasi",
      "footer-services": "Xizmatlar",
      "footer-portfolio": "Portfolio",
      "footer-blog": "Blog",
      "footer-vacancies": "Vakansiya",
      "footer-about": "Kompaniya",
      //error
      "error-title": "Sahifa topilmadi",
      "error-description":
        "Kechirasiz, lekin siz qidirayotgan sahifa mavjud emas.",
      "error-btn": "Bosh sahifa",

      // recaptcha
      "recaptcha-title":
        "Xavfsizlik tekshiruvi: Robot emasligingizni tasdiqlang",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng:
    navigator.language.slice(0, 2) === ("ru" || "uz" || "en")
      ? JSON.parse(localStorage.getItem("Lang"))
        ? JSON.parse(localStorage.getItem("Lang"))
        : navigator.language.slice(0, 2)
      : "en",
  fallbackLng: ["uz", "en"],
  interpolation: {
    escapeValue: false,
  },
});
export default i18n;
