import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingBar from "react-top-loading-bar";
import { Helmet } from "react-helmet";

import OrderModal from "../../Components/Modal/Modal";
import Appform from "../../Components/Appform/Appform";
import ServicesService from "../../services/ServicesService";
import SuccessModal from "../../Components/Modal/SuccessModal";
import "./Website.css";

function Website(props) {
  const { slug } = useParams();
  const { t } = useTranslation();
  const ref = useRef(null);

  const [other, setOther] = useState([]);
  const [service, setService] = useState({
    services: [],
    descriptions: [],
    steps: [],
  });
  const [post, setPost] = useState({
    id: 0,
    posts: [],
  });
  const [snack, setSnack] = useState(false);

  const navigate = useNavigate();

  const openSuccess = (id, posts) => {
    setPost({ id: id, posts: posts });
    setSnack(true);
  };

  const handleClose = () => {
    setSnack(false);
  };

  const Service = new ServicesService();

  useEffect(() => {
    ref.current.continuousStart();
    Service.getIndex(slug, props.lang)
      .then((res) => {
        setService(res.data.service);
        setOther(res.data.other_services);
        ref.current.complete();
      })
      .catch((error) => {
        return navigate("/404");
      });
  }, [slug, props.lang]);

  return (
    <div className="website">
      <div>
        <LoadingBar height={3} color="red" ref={ref} />

        <Helmet>
          <meta charSet="utf-8" />
          <title>{`USOFT - ${service.title}`}</title>
          <meta name="description" content="Все о наших услугах" />
        </Helmet>

        <div className="container">
          <ol
            itemscope
            itemtype="http://schema.org/BreadcrumbList"
            className="breadcrumb"
          >
            <li
              itemprop="itemListElement"
              itemscope
              itemtype="http://schema.org/ListItem"
            >
              <Link
                itemscope
                itemtype="http://schema.org/Thing"
                itemprop="item"
                to="/"
              >
                <span itemprop="name"> {t("main")}</span>
              </Link>
              <meta itemprop="position" content="1" />
            </li>
            <li
              itemprop="itemListElement"
              itemscope
              itemtype="http://schema.org/ListItem"
            >
              {" "}
              /
              <Link
                itemscope
                itemtype="http://schema.org/Thing"
                itemprop="item"
                to="/services"
              >
                <span itemprop="name"> {t("services")}</span>
              </Link>
              <meta itemprop="position" content="2" />
            </li>
            {service.category ? (
              <li
                itemprop="itemListElement"
                itemscope
                itemtype="http://schema.org/ListItem"
              >
                /
                <Link
                  itemscope
                  itemtype="http://schema.org/Thing"
                  itemprop="item"
                  to={`/services/${service.category_slug}`}
                >
                  <span itemprop="name"> {service.category}</span>
                </Link>
                <meta itemprop="position" content="3" />
              </li>
            ) : null}
            <li
              itemprop="itemListElement"
              itemscope
              itemtype="http://schema.org/ListItem"
            >
              /<span itemprop="name"> {service.title}</span>
              <meta itemprop="position" content="4" />
            </li>
          </ol>
          <h1>{service.title} </h1>
          <div
            className="website-parag"
            dangerouslySetInnerHTML={{ __html: service.description }}
          ></div>
          <div className="row justify-content-center">
            {service.services.map((v) => (
              <div
                key={v.id}
                className="col-lg-4 col-md-6 col-sm-7 mb-4 website-carddd"
              >
                <div className="wweebb">
                  <div className="title">
                    <h3>{v.title} </h3>
                    <img width="190" height="190" src={v.image} alt={v.title} />
                    <p className="website-card-parag">{v.short_description}</p>
                  </div>
                  <div>
                    <Link
                      to={`/services/${v.slug}`}
                      className="btn"
                      style={{
                        backgroundColor: `${v.color}`,
                        boxShadow: `0 0 40px -10px ${v.color}`,
                      }}
                    >
                      {t("go-to")}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row justify-content-center">
            {service.descriptions.map((item) => (
              <div
                key={item.id}
                className="col-lg-4 col-md-6 col-sm-7 website-carddd"
              >
                <div className="website-card">
                  <div className="title">
                    <div>
                      <h3 className="webmarkettitle">{item.title}</h3>
                      <img
                        width="190"
                        height="190"
                        src={item.icon}
                        alt={item.title}
                      />
                      <p className="website-card-parag">{item.body}</p>
                    </div>
                    <OrderModal
                      lang={props.lang}
                      openSuccess={openSuccess}
                      clazz={"btn"}
                      style={{
                        backgroundColor: `${item.color}`,
                        boxShadow: `0 0 40px -10px ${item.color}`,
                      }}
                      name={t("prices-order")}
                      modaltitle={t("application-title")}
                    />
                  </div>
                </div>
              </div>
            ))}
            <SuccessModal
              lang={props.lang}
              id={post.id}
              posts={post.posts}
              success={snack}
              handleClose={handleClose}
            />
          </div>
          {service.steps[0] ? (
            <div className="row justify-content-center mt-5 ">
              <h2 className="text-center">{t("Application-stages")}</h2>
              {service.steps.map((item) => (
                <div key={item.id} className="col-lg-4 col-md-6 col-sm-7">
                  <div className="webmarketbox">
                    <div
                      className="mobileappicon"
                      style={{
                        backgroundColor: `${item.color}`,
                        boxShadow: `0 0 40px -10px ${item.color}`,
                      }}
                    >
                      <img
                        width="190"
                        height="190"
                        src={item.icon}
                        alt={item.title}
                      />
                    </div>
                    <h2 className="webmarkettitle">{item.title}</h2>
                    <p className="webmarket-parag">{item.body}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          <h2 className="website-link-title">{t("as-well-as")} </h2>
          <div className="row mt-5">
            {other.map((v) => (
              <div key={v.id} className="col-xl-3 col-lg-4 col-md-6">
                <div>
                  <Link className="linkbox" to={`/services/${v.slug}`}>
                    {v.title}
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <Appform lang={props.lang} />
        </div>
      </div>
    </div>
  );
}
export { Website };
