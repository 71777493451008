import axios from 'axios'

class SettingsService {
    getSettings = (lang = 'uz') => {
        return axios.get('https://api.usoft.uz/api/settings/', {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }
}

export default SettingsService