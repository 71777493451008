import "./PhoneButton.css";

function PhoneButton() {
  return (
    <div className="phone-button-wrapper">
      <div className="animation-box"></div>
      <div className="animation-box-fill"></div>

      <a
        href="tel:+998 71 200 12 00"
        className="animation-img-circle"
        style={{ textDecoration: "none" }}
      ></a>
    </div>
  );
}

export default PhoneButton;
