import { faPhoneAlt, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import logo from "../../Images/logo.svg";
import { DropdownButton } from "react-bootstrap";
import ServicesService from "../../services/ServicesService";
import i18next from "i18next";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTelegramPlane,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import SettingsService from "../../services/SettingsService";
import { debounce } from "./debounce";
import OrderModal from "../Modal/Modal";
import SuccessModal from "../Modal/SuccessModal";
import "./Header.css";

function Header(props) {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [snack, setSnack] = useState(false);
  const [post, setPost] = useState({
    id: 0,
    posts: [],
  });
  const [lang, setLang] = useState(props.lang);
  useEffect(() => {
    setLang(props.lang);
  }, [props]);

  const [dropLang, setDropLang] = useState(false);

  const showDropdown = (e) => {
    setDropLang(!dropLang);
  };
  const hideDropdown = (e) => {
    setDropLang(false);
  };

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
        currentScrollPos < 70
    );
    setPrevScrollPos(currentScrollPos);
    hideDropdown();
  }, 40);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const [click, setClick] = useState(false);
  const handleClick = () => {
    document.body.classList.toggle("active");
    setClick(!click);
  };
  const closeMobileMenu = () => {
    document.body.classList.remove("active");
    setClick(false);
  };
  const [services, setServices] = useState([]);
  const [links, setLinks] = useState([]);
  const { t } = useTranslation();
  const { id } = useParams();
  const Service = new ServicesService();
  const Setting = new SettingsService();

  useEffect(() => {
    Service.getServices(id).then((res) => {
      setServices(res.data.services);
    });
    Setting.getSettings().then((res) => {
      setLinks(res.data.settings);
    });
  }, []);

  const jsUcfirst = (string) => {
    if (string) {
      return string[0].toUpperCase() + string.slice(1);
    }
  };

  const [selected, setSelected] = useState(
    JSON.parse(localStorage.getItem("Lang"))
  );

  const handleChange = (s) => {
    setSelected(s);
    i18next.changeLanguage(s);
    props.onChangeLang(s);
  };

  const openSuccess = (id, posts) => {
    setPost({ id: id, posts: posts });
    setSnack(true);
  };

  const closeSuccess = () => {
    setSnack(false);
  };

  return (
    <header
      className="navbar"
      style={{
        transform: `translateY(${click || visible ? "0" : "-80px"})`,
        transition: "transform 0.3s ease-in-out",
      }}
    >
      <div className="container">
        <Link to="/" className="navbar-logo">
          <img src={logo} alt="logo" onClick={closeMobileMenu} />
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          {click ? (
            <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
          )}
        </div>
        <div className={click ? "rasul active" : "rasul"}>
          <ul className="nav-menu" style={{ marginBottom: 0 }}>
            <li className="nav-item">
              <NavLink
                className="nav-links "
                activeclassname="active"
                to="/"
                onClick={closeMobileMenu}
              >
                {t("main")}
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink
                className="nav-links"
                activeclassname="active"
                to="/services"
                onClick={closeMobileMenu}
              >
                {t("services")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-links"
                activeclassname="active"
                to="/portfolio"
                onClick={closeMobileMenu}
              >
                {t("portfolio")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-links"
                activeclassname="active"
                to="/blog"
                onClick={closeMobileMenu}
              >
                {t("footer-blog")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-links"
                activeclassname="active"
                to="/contacts"
                onClick={closeMobileMenu}
              >
                {t("contacts")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-links"
                activeclassname="active"
                to="/vacancy"
                onClick={closeMobileMenu}
              >
                {t("vacancies")}
              </NavLink>
            </li>
            <div className="header-right">
              <div className="consultation">
                <OrderModal
                  lang={props.lang}
                  modaltitle={t("application-title")}
                  openSuccess={openSuccess}
                  font={
                    <FontAwesomeIcon
                      className="me-2"
                      icon={faPhoneAlt}
                    ></FontAwesomeIcon>
                  }
                  onClick={closeMobileMenu}
                  name={t("consultation")}
                ></OrderModal>
              </div>
              <SuccessModal
                lang={props.lang}
                id={post.id}
                posts={post.posts}
                success={snack}
                handleClose={closeSuccess}
              ></SuccessModal>
              <div className="bottom-lang">
                <DropdownButton
                  show={dropLang}
                  onClick={showDropdown}
                  title={jsUcfirst(selected) || "Ru"}
                  onSelect={handleChange}
                >
                  <div className="langgg">
                    {selected != "uz" ? (
                      <span onClick={() => handleChange("uz") + hideDropdown}>
                        {selected == "Uz" ? selected : "Uz"}
                      </span>
                    ) : (
                      ""
                    )}
                    {selected != "ru" ? (
                      <span onClick={() => handleChange("ru") + hideDropdown}>
                        {selected == "Ru" ? selected : "Ru"}
                      </span>
                    ) : (
                      ""
                    )}
                    {selected != "en" ? (
                      <span onClick={() => handleChange("en") + hideDropdown}>
                        {selected == "En" ? selected : "En"}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </DropdownButton>
              </div>
            </div>
            <div className="bottom-lang">
              <span
                className={selected == "uz" ? "langActive" : "null"}
                onClick={() => handleChange("uz")}
              >
                {selected == "Uz" ? selected : "Uz"}
              </span>
              <span
                className={selected == "ru" ? "langActive" : "null"}
                onClick={() => handleChange("ru")}
              >
                {selected == "Ru" ? selected : "Ru"}
              </span>
              <span
                className={selected == "en" ? "langActive" : "null"}
                onClick={() => handleChange("en")}
              >
                {selected == "En" ? selected : "En"}
              </span>
            </div>
            <div className="header-bottom">
              <a href="tel:+998 71 200 12 00" onClick={closeMobileMenu}>
                +998 71 200 12 00
              </a>
              <div className="icon-links">
                {links.social ? (
                  <a
                    href={links.social.facebook}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                ) : null}
                {links.social ? (
                  <a
                    href={links.social.instagram}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                ) : null}
                {links.social ? (
                  <a
                    href={links.social.telegram}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faTelegramPlane} />
                  </a>
                ) : null}
                {links.social ? (
                  <a
                    href={links.social.linkedin}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                ) : null}
              </div>
            </div>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
