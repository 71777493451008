import axios from 'axios'

class StagesService {
    getStages = (lang = 'uz') => {
        return axios.get('https://api.usoft.uz/api/development_steps/', {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }
}

export default StagesService