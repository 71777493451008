import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Form } from "react-bootstrap";
import ApplicationService from "../../services/ApplicationService";
import { TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import ReCAPTCHA from "react-google-recaptcha";
import "./Modal.css";
import useRecaptcha from "../../hooks/useRecaptcha";
import { useEffect } from "react";
import RecaptchaService from "../../services/RecaptchaService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#291258",
  color: "#fff",
  borderRadius: "25px",
  boxShadow: 24,
  maxWidth: 370,
  width: "100%",
  minHeight: 270,
  pt: 3,
  px: 4,
  pb: 2,
};

const override = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: red;
  width: 120px;
  min-width: 100%;
  margin: 20px 0;
`;

export default function RecaptchaModal(props) {
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();

  const verifyRecaptcha = new RecaptchaService();

  const handleOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    if (!!capchaToken) {
      verifyRecaptcha
        .verifyRecaptcha("uz", { "g-recaptcha-response": capchaToken })
        .then((res) => {
          props.confirmAction(res?.data?.token);
        });
    }
  }, [capchaToken]);

  return (
    <div>
      <div className="send-btn-modal1" onClick={handleOpen}>
        {props.font} {props.name}
      </div>
      <Modal
        open={props?.open}
        onClose={handleClose}
        sx={{ minHeight: "300px" }}
      >
        <Box sx={{ ...style }}>
          <span className="closebtn" onClick={handleClose}>
            X
          </span>
          <h2 className="modal-title">{props.modaltitle}</h2>
          <ReCAPTCHA
            ref={recaptchaRef}
            style={{ marginTop: 30 }}
            sitekey="6LcqXo0qAAAAACdHz8x7RTkX-Tuax8zvcYVvgr_7"
            onChange={handleRecaptcha}
          />
        </Box>
      </Modal>
    </div>
  );
}
