import axios from 'axios'

class SliderService {
    getSliders = (lang = 'uz') => {
        return axios.get('https://api.usoft.uz/api/sliders/', {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }
}

export default SliderService