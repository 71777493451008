import axios from 'axios'

class StaticService {
    getStatics = (lang = "uz") => {
        return axios.get('https://api.usoft.uz/api/statistics/', {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }
}

export default StaticService