import React, { useEffect, useState } from "react";
import Application from "../../Components/Application/Application";
import NumbersBox from "../../Components/NumbersBox/NumbersBox";
import Ourworks from "../../Components/Ourworks/Ourworks";
import Prices from "../../Components/Prices/Prices";
import SimpleSlider from "../../Components/Slider/Slider";
import Stages from "../../Components/Stages/Stages";
import Comment from "../../Components/Comment/Comment";
import Clients from "../../Components/Clients/Clients";
import FeedbackService from "../../services/FeedbackService";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function Main(props) {
  const [comments, setComments] = useState([]);
  const { t } = useTranslation();

  const feedback = new FeedbackService();

  useEffect(() => {
    feedback.getFeedbacks(props.lang).then((res) => {
      setComments(res.data.feedbacks);
    });
  }, [props.lang]);

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>USOFT - {t("main-tab-name")}</title>
        <meta
          name="description"
          content="Создание сайтов в Ташкенте. Разработка сайтов в Ташкенте. Контекстная реклама в Ташкенте."
        />
      </Helmet>

      <SimpleSlider lang={props.lang} />
      <NumbersBox lang={props.lang} />
      <Prices lang={props.lang} />
      <Ourworks lang={props.lang} />
      <Stages lang={props.lang} />
      <Application lang={props.lang} />
      <Comment comments={comments} />
      <Clients />
    </main>
  );
}

export default Main;
