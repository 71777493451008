import React from "react";
import { Helmet } from "react-helmet";

function Policy() {
  return (
    <div className="policy container" style={{ marginTop: "100px" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>USOFT - Policy</title>
        <meta
          name="description"
          content="ПОЛИТИКА обработки персональных данных OOO «ROUND UMBRELLA»"
        />
      </Helmet>

      <p>ПОЛИТИКА обработки персональных данных OOO «ROUND UMBRELLA»</p>
      <h5>1. Общие положения.</h5>
      <p>
        Настоящая политика обработки персональных данных составлена в
        соответствии с требованиями Закона Республики Узбекистан «О персональных
        данных» от 02.07.2019г. №ЗРУ-547 и определяет порядок обработки
        персональных данных и меры по обеспечению безопасности персональных
        данных со стороны OOO «ROUND UMBRELLA» (далее – Оператор).
      </p>
      <p>
        Настоящая политика Оператора в отношении обработки персональных данных
        (далее – Политика) применяется ко всей информации, которую Оператор
        может получить о посетителях веб-сайта в сети Интернет по адресу:
        https://usoft.uz (далее – Сайт).
      </p>
      <p>
        Настоящая Политика распространяется непосредственно на Сайт и на
        информацию, получаемую с его помощью. Политика не распространяется ни на
        какие другие сайты и не применима к веб-сайтам третьих лиц, которые
        могут содержать упоминание о Сайте и с которых могут делаться ссылки на
        Сайт, а также ссылки с Сайта на другие сайты сети Интернет.
      </p>
      <h5>2. Основные понятия, используемые в Политике</h5>
      <p>
        2.1. Автоматизированная обработка персональных данных – обработка
        персональных данных с помощью средств вычислительной техники;
      </p>
      <p>
        2.2. Блокирование персональных данных – временное прекращение обработки
        персональных данных (за исключением случаев, если обработка необходима
        для уточнения персональных данных);
      </p>
      <p>
        2.3. Информационная система персональных данных — совокупность
        содержащихся в базах данных персональных данных, и обеспечивающих их
        обработку информационных технологий, и технических средств;
      </p>
      <p>
        2.4. Обезличивание персональных данных — действия, в результате которых
        невозможно определить без использования дополнительной информации
        принадлежность персональных данных конкретному Пользователю или иному
        субъекту персональных данных;
      </p>
      <p>
        2.5. Обработка персональных данных – любое действие (операция) или
        совокупность действий (операций), совершаемых с использованием средств
        автоматизации или без использования таких средств с персональными
        данными, включая сбор, запись, систематизацию, накопление, хранение,
        уточнение (обновление, изменение), извлечение, использование, передачу
        (распространение, предоставление, доступ), обезличивание, блокирование,
        удаление, уничтожение персональных данных;
      </p>
      <p>
        2.6. Персональные данные – любая информация, относящаяся прямо или
        косвенно к определенному или определяемому Пользователю веб-сайта в сети
        Интернет по адресу: https://usoft.uz;
      </p>
      <p>
        2.7. Пользователь – любой посетитель веб-сайта в сети Интернет по
        адресу: https://usoft.uz//
      </p>
      <p>
        2.8. Предоставление персональных данных – действия, направленные на
        раскрытие персональных данных определенному лицу или определенному кругу
        лиц;
      </p>
      <p>
        2.9. Распространение персональных данных – любые действия, направленные
        на раскрытие персональных данных неопределенному кругу лиц (передача
        персональных данных) или на ознакомление с персональными данными
        неограниченного круга лиц, в том числе обнародование персональных данных
        в средствах массовой информации, размещение в
        информационно-телекоммуникационных сетях или предоставление доступа к
        персональным данным каким-либо иным способом;
      </p>
      <p>
        2.10. Уничтожение персональных данных – любые действия, в результате
        которых персональные данные уничтожаются безвозвратно с невозможностью
        дальнейшего восстановления содержания персональных данных в
        информационной системе персональных данных и (или) результате которых
        уничтожаются материальные носители персональных данных.
      </p>
      <h5>
        3. Оператор может обрабатывать следующие персональные данные
        Пользователя.
      </h5>
      <ul>
        <li>Фамилия, имя, отчество;</li>
        <li>Фирменное наименование;</li>
        <li>Номер телефона;</li>
        <li>Электронный адрес;</li>
        <li>
          Также на сайте происходит сбор и обработка обезличенных данных о
          посетителях (в т.ч. файлов «cookie»).
        </li>
      </ul>
      <h5>4. Цели обработки персональных данных.</h5>
      <p>
        4.1. Цель обработки персональных данных Пользователя — информирование
        Пользователя посредством отправки электронных писем по их обращениям
        посредством «Обратной связи».
      </p>
      <p>
        4.2. Также Оператор имеет право направлять Пользователю уведомления о
        новых продуктах и услугах, специальных предложениях и различных
        событиях. Пользователь всегда может отказаться от получения
        информационных сообщений, направив Оператору письмо на адрес электронной
        почты info@usoft.uz с пометкой «Отказ от уведомлениях о новых продуктах
        и услугах и специальных предложениях».
      </p>
      <p>
        4.3. Обезличенные данные Пользователей, собираемые с помощью сервисов
        интернет-статистики, служат для сбора информации о действиях
        Пользователей на сайте, улучшения качества сайта и его содержания.
      </p>
      <h5>5. Правовые основания обработки персональных данных.</h5>
      <p>
        5.1. Оператор обрабатывает персональные данные Пользователя только в
        случае их заполнения и/или отправки Пользователем самостоятельно через
        специальные форму «Обратной связи», расположенной на Сайте
        https://usoft.uz. Заполняя соответствующие формы и/или отправляя свои
        персональные данные Оператору, Пользователь выражает свое согласие с
        данной Политикой.
      </p>
      <p>
        5.2. Оператор обрабатывает обезличенные данные о Пользователе в случае,
        если это разрешено в настройках браузера Пользователя (включено
        сохранение файлов «cookie» и использование технологии JavaScript).
      </p>
      <h5>
        6 Порядок сбора, хранения, передачи и других видов обработки
        персональных данных.
      </h5>
      <p>
        6.1. Безопасность персональных данных, которые обрабатываются
        Оператором, обеспечивается путем реализации правовых, организационных и
        технических мер, необходимых для выполнения в полном объеме требований
        действующего законодательства в области защиты персональных данных.
      </p>
      <p>
        6.2. Оператор обеспечивает сохранность персональных данных и принимает
        все возможные меры, исключающие доступ к персональным данным
        неуполномоченных лиц.
      </p>
      <p>
        6.3. Персональные данные Пользователя никогда, ни при каких условиях не
        будут переданы третьим лицам, за исключением случаев, связанных с
        исполнением действующего законодательства.
      </p>
      <p>
        6.4. В случае выявления неточностей в персональных данных, Пользователь
        может актуализировать их самостоятельно, путем направления Оператору
        уведомление на адрес электронной почты Оператора info@usoft.uz с
        пометкой «Актуализация персональных данных».
      </p>
      <p>
        6.5. Срок обработки персональных данных является неограниченным.
        Пользователь может в любой момент отозвать свое согласие на обработку
        персональных данных, направив Оператору уведомление посредством
        электронной почты на электронный адрес Оператора info@usoft.uz с
        пометкой «Отзыв согласия на обработку персональных данных».
      </p>
      <h5>7. Разрешение споров.</h5>
      <p>
        7.1. До обращения в суд с иском по спорам, возникающим из отношений по
        настоящей Политике между Оператором и Пользователем, обязательным
        является предъявление претензии (письменного предложения о добровольном
        урегулировании спора).
      </p>
      <p>
        7.2. При недостижении соглашения спор будет передан на рассмотрение в
        суд в соответствии с действующим законодательством Республики
        Узбекистан.
      </p>
      <p>
        7.3. К настоящей Политике конфиденциальности и отношениям между
        Оператором и Пользователем применяется действующее законодательство
        Республики Узбекистан.
      </p>
      <h5>8. Заключительные положения.</h5>
      <p>
        8.1. Оператор вправе вносить изменения в настоящую Политику без согласия
        Пользователя.
      </p>
      <p>
        8.2. Пользователь может получить любые разъяснения по интересующим
        вопросам, касающимся обработки его персональных данных, обратившись к
        Оператору с помощью электронной почты info@usoft.uz.
      </p>
      <p>
        8.3. В данном документе будут отражены любые изменения политики
        обработки персональных данных Оператором. Политика действует бессрочно
        до замены ее новой версией.
      </p>
      <p>
        8.4. Актуальная версия Политики в свободном доступе расположена в сети
        Интернет по адресу https://usoft.uz.
      </p>
      <h5>9 Оператор.</h5>
      <p className="ms-4">
        ООО "ROUND UMBRELLA" <br />
        Телефон: +998 71 200-12-00 <br />
        Электронная почта: info@usoft.uz
      </p>
    </div>
  );
}

export default Policy;
