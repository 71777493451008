import axios from 'axios'

class VacancyService {
    getVacancy = (lang = 'uz') => {
        return axios.get('https://api.usoft.uz/api/vacancies/', {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }

    sendSummary = (phone, vacancy_id, file, lang = 'uz') => {
        const form = new FormData()
        form.append('phone', phone)
        form.append('vacancy_id', vacancy_id)
        form.append('file', file)
        return axios.post('https://api.usoft.uz/api/vacancies', form, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Locale: lang.toLowerCase()
            }
        })
    }
}

export default VacancyService