import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
import ApplicationService from "../../services/ApplicationService";
import SuccessModal from "../Modal/SuccessModal";
import "./Application.css";
import RecaptchaModal from "../RecaptchaModal/RecaptchaModal";

function Application(props) {
  const [open, setOpen] = useState(false);
  const [openRecaptcha, setOpenRecaptcha] = useState(false);
  const [pend, setPend] = useState(false);
  const [phone, setPhone] = useState();
  const [post, setPost] = useState({
    id: 0,
    posts: [],
  });

  const { t } = useTranslation();
  const application = new ApplicationService();

  const onChange = (event) => {
    setPhone(event.target.value);
  };

  const submit = (recaptcha) => {
    application
      .createApplication(phone, props.lang, null, recaptcha)
      .then((response) => {
        setPost({
          id: response.data.id,
          posts: response.data.posts,
        });
        setOpen(true);
        setPend(false);
        setOpenRecaptcha(false);
      });
  };

  const handleOpenRecaptcha = (event) => {
    event.preventDefault();

    event.target.reset();
    setOpenRecaptcha(true);
  };

  const handleClose = () => {
    setPhone("");
    setOpen(false);
  };

  const override = css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: red;
    width: 120px;
    min-width: 100%;
  `;
  return (
    <>
      <div className="application">
        <div className="container">
          <h2>{t("application-title")}</h2>
          <p>{t("application-bottom")}</p>
          <form
            className="send-box"
            id="send-box"
            onSubmit={handleOpenRecaptcha}
          >
            <InputMask
              mask="+\9\9\8 (99) 999-99-99"
              disabled={false}
              maskChar={null}
              value={phone}
              onChange={onChange}
            >
              {() => (
                <TextField
                  className="text-input w-100 sss"
                  label={t("telephone")}
                  id="application-phone"
                  required
                />
              )}
            </InputMask>

            <SuccessModal
              lang={props.lang}
              id={post.id}
              posts={post.posts}
              success={open}
              handleClose={handleClose}
            />
            <div className="send-hover">
              {pend ? (
                <BeatLoader
                  css={override}
                  color={"#ff7878"}
                  loading={pend}
                  size={20}
                />
              ) : (
                <button type="submit" variant="inf" className="send">
                  {t("send-request")}
                  <FontAwesomeIcon icon={faLongArrowAltRight} />
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      <RecaptchaModal
        modaltitle={t("recaptcha-title")}
        open={openRecaptcha}
        setOpen={setOpenRecaptcha}
        confirmAction={submit}
      />
    </>
  );
}

export default Application;
