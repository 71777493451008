import axiosInstance from "./axios";

class RecaptchaService {
  verifyRecaptcha = (lang = "uz", data) => {
    return axiosInstance.post(
      "https://api.usoft.uz/api/verify-recaptcha",
      data
    );
  };
}

export default RecaptchaService;
