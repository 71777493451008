import axios from 'axios'

class ProjectService {

    getCategories = (lang = 'uz', limit = 0) => {
        return axios.get('https://api.usoft.uz/api/projects/categories?limit=' + limit, {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }

    getProjects = (lang = 'uz') => {
        return axios.get('https://api.usoft.uz/api/projects/', {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }

    getIndex = (id, lang = 'uz') => {
        return axios.get('https://api.usoft.uz/api/projects/' + id, {
            headers: {
                Locale: lang.toLowerCase()
            }
        })
    }
}

export default ProjectService