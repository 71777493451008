import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Form } from "react-bootstrap";
import ApplicationService from "../../services/ApplicationService";
import { TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import "./Modal.css";
import useRecaptcha from "../../hooks/useRecaptcha";
import RecaptchaService from "../../services/RecaptchaService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#291258",
  color: "#fff",
  borderRadius: "25px",
  boxShadow: 24,
  maxWidth: 370,
  width: "100%",
  minHeight: 415,
  pt: 3,
  px: 4,
  pb: 2,
};

const override = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: red;
  width: 120px;
  min-width: 100%;
  margin: 20px 0;
`;

export default function OrderModal(props) {
  const [open, setOpen] = React.useState(false);
  const [pend, setPend] = React.useState(false);
  const [lang, setLang] = React.useState(props.lang);
  const { t } = useTranslation();
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();
  React.useEffect(() => {
    setLang(props.lang);
  }, [props]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const application = new ApplicationService();
  const verifyRecaptcha = new RecaptchaService();

  const submit = (event) => {
    event.preventDefault();

    verifyRecaptcha
      .verifyRecaptcha(lang, { "g-recaptcha-response": capchaToken })
      .then((res) => {
        console.log(res?.data?.token);
        setPend(true);
        let phone = document.querySelector("#modal-phone").value;
        application
          .createApplication(phone, lang, props.service_id, res?.data?.token)
          .then((res) => {
            setPend(false);
            setOpen(false);
            props.openSuccess(res.data.id, res.data.posts);
          });
      });
  };

  return (
    <div className={props.clazz} style={props.style}>
      <div className="send-btn-modal1" onClick={handleOpen}>
        {props.font} {props.name}
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style }}>
          <span className="closebtn" onClick={handleClose}>
            X
          </span>
          <h2 className="modal-title">{props.modaltitle}</h2>
          <Form className="send-box d-block" onSubmit={submit}>
            <InputMask
              mask="+\9\9\8 (99) 999-99-99"
              disabled={false}
              maskChar={null}
            >
              {() => (
                <TextField
                  className="text-input"
                  label={t("telephone")}
                  required
                  id="modal-phone"
                />
              )}
            </InputMask>

            <ReCAPTCHA
              ref={recaptchaRef}
              style={{ marginTop: 30 }}
              sitekey="6LcqXo0qAAAAACdHz8x7RTkX-Tuax8zvcYVvgr_7"
              onChange={handleRecaptcha}
            />

            <div>
              {pend ? (
                <BeatLoader
                  css={override}
                  color={"#ff7878"}
                  loading={pend}
                  size={20}
                />
              ) : (
                <Button
                  className="modalbtn"
                  variant="primary"
                  type="submit"
                  disabled={!capchaToken}
                >
                  {t("send-request")}
                </Button>
              )}
            </div>
            <div className="px-2">
              <p className="modal-under">
                {t("application-call")} <br />{" "}
                <a href="tel:+998 71 200 12 00">+998 71 200 12 00</a>
              </p>
              <p className="modal-bottom">
                {t("policy-about")}{" "}
                <a href="/policy" target="_blank">
                  {t("policy")}
                </a>{" "}
              </p>
            </div>
          </Form>
        </Box>
      </Modal>
    </div>
  );
}
