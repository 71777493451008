export function formatUzPhoneNumber(phone) {
  if (!phone) return "";

  const cleaned = phone.replace(/\D/g, "");

  if (cleaned.length !== 12 || !cleaned.startsWith("998")) {
    return phone;
  }

  return `+${cleaned.slice(0, 3)} ${cleaned.slice(3, 5)} ${cleaned.slice(
    5,
    8
  )} ${cleaned.slice(8, 10)} ${cleaned.slice(10)}`;
}
