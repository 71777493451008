import axios from "axios";

class ApplicationService {
  url = process.env.REACT_APP_BASE_URL;

  createApplication = (phone, lang = "uz", service_id = null, token) => {
    const form = new FormData();
    form.append("phone", phone);
    form.append("token", token);
    if (service_id) form.append("service_id", service_id);
    return axios.post("https://api.usoft.uz/api/application/", form, {
      headers: {
        Locale: lang.toLowerCase(),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  };
}

export default ApplicationService;
